import React from "react";
import styled from "styled-components";

const ContactModul = () => {
  return (
      <MainContainer>
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front card">
              <i className="far fa-envelope"></i>
            </div>
            <div className="flip-card-back card mail">
              <a href="mailto:hello@banucicektulu.com" target="_blank">
                <i className="far fa-envelope"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front card">
              <i className="fab fa-instagram"></i>
            </div>
            <div className="flip-card-back card ig">
              <a
                  href="https://www.instagram.com/banusoundd"
                  rel="noopener noreferrer"
                  target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front card">
              <i className="fab fa-soundcloud"></i>
            </div>
            <div className="flip-card-back card sc">
              <a
                  href="https://soundcloud.com/banusound"
                  rel="noopener noreferrer"
                  target="_blank"
              >
                <i className="fab fa-soundcloud"></i>
              </a>
            </div>
          </div>
        </div>
      </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  z-index: 1000;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: absolute;
  top: 100%;
  background-color: white;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAG0lEQVQYV2P8z8Dwn5GBgZEBCuAMDAGYSgwVALLeBAWsNw3pAAAAAElFTkSuQmCC)
    repeat;
    position: absolute;
    //z-index: -1;
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
  }

  i {
    font-size: 6.3vw;
  }

  .flip-card {
    background-color: transparent;
    width: 10vw;
    height: 10vw;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-color: #fff;
    color: black;
  }

  .flip-card-back {
    color: white;
    transform: rotateY(180deg);
  }

  a {
    color: white;
    text-decoration: none;
  }

  .cv {
    background-color: #2980b9;
    font-family: "Rubik", sans-serif;
  }

  .ig {
    background: #d6249f;
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  }

  .mail {
    background-color: grey;
  }

  .sc {
    background-color: #ff7700;
  }


  @media (min-width: 600px)   {
    height: 100px;
  }
  @media (min-width: 1024px)  {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid black;
    position: relative;
    top: 0;
    border-bottom: none;
    

    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Rubik", sans-serif;
      font-weight: 700;
    }

    i {
      font-size: 1.3vw;
    }

    .flip-card {
      background-color: transparent;
      width: 2vw;
      height: 2vw;
      perspective: 1000px;
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .flip-card-front {
      background-color: #fff;
      color: black;
    }

    .flip-card-back {
      color: white;
      transform: rotateY(180deg);
    }

    a {
      color: white;
      text-decoration: none;
    }

    .cv {
      background-color: #2980b9;
      font-family: "Rubik", sans-serif;
    }

    .ig {
      background: #d6249f;
      background: radial-gradient(
          circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
      );
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    }

    .mail {
      background-color: grey;
    }

    .sc {
      background-color: #ff7700;
    }
  }
`;

export default ContactModul;
