import React, {useContext, useEffect} from "react";
import styled from "styled-components";

import TransitionRoute from "../NavLogic/TransitionRoute";
import Home from "../Main/Home";
import About from "../Main/About";
import Exhibition from "./Exhibition";
import Teaching from "./Teaching";
import Music from "../Main/Music";
import ExhibitionDetails from "./ExhibitionDetails";
import {Context} from "../../Context/Context";
import ReleaseDetials from "./ReleaseDetails";

const Main = styled.div`
    width: 100%;
    height: calc(100% - 19vh);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1;
    overflow: scroll;
  
  @media (min-width: 600px)   {} 
  @media (min-width: 1024px)  {
    width: 77%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    overflow: scroll;
  }
`;

const MainSection = () => {
    const {exhibitions, setExhibitions,  music, setMusic} = useContext(Context);


    useEffect(() => {
        fetch('https://api.storyblok.com/v2/cdn/stories/exhibitions?version=published&token=sTO5zBZz3QBovHIeO7jTfwtt&cv=1726749708')
            .then(response => response.json())
            .then(data => {
                setExhibitions(data?.story?.content?.exhibition)
            })
            .catch(error => {
                // Handle any errors
            });

        fetch('https://api.storyblok.com/v2/cdn/stories/music?version=published&token=sTO5zBZz3QBovHIeO7jTfwtt&cv=1726749708')
            .then(response => response.json())
            .then(data => {
                setMusic(data?.story?.content?.releases)
            })
            .catch(error => {
                // Handle any errors
            });
    }, [setExhibitions, setMusic])

  return (
    <Main>
      <TransitionRoute path="/" exact component={Home} />
      <TransitionRoute path="/about" exact component={About} />
      <TransitionRoute path="/exhibition" exact component={Exhibition} />
      <TransitionRoute path="/music" exact component={Music} />
      <TransitionRoute path="/teaching" exact component={Teaching} />
        {exhibitions?.map(i => <TransitionRoute key={i._uid} path={`/exhibition/${i.title.replace(' ', '_').toLowerCase()}`} exact component={ExhibitionDetails} />)}
        {music?.map(i => <TransitionRoute key={i._uid} path={`/music/${i.AlbumTitle.replace(' ', '_').toLowerCase()}`} exact component={ReleaseDetials} />)}

    </Main>
  );
};

export default MainSection;
