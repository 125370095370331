import React, { useEffect, useState} from "react";
import styled from "styled-components";
import { render } from 'storyblok-rich-text-react-renderer';



const Teach = styled.div`
  width: 100%;
  padding: 2vw 1rem;
  font-family: "Rubik", sans-serif;

  .te-header {
    font-family: "Rubik", sans-serif;
    font-size: 1.3rem;
    margin: 0;
  }

  .te-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
      list-style-position: outside;
      margin-bottom: 2rem;
    }
  }
  
  .te-subline.te-row {
    display: flex;
    flex-direction: row;
    gap: .5rem;
  }
  
  .te-gap {
    margin: 1rem 0;
    font-style: italic;
    
    p {
      font-size: 1rem;
      font-weight: 300;
    }
  }
  
  .te-text {
    margin: 1rem 0;
    
    p {
      font-size: 1rem;
      font-weight: 300;
    }
  }
  
  .te-link {
    color: #ff00e5;
    text-decoration: none;
    margin-top: 1rem;

    &:hover {
      color: blue;
    }
  }

  h2 {
    color: black;
    font-family: "Rubik", sans-serif;
    font-size: 2rem;
    width: 100%;
    margin: 0;
  }
  
  p {
    margin: 0;
    font-size: 1.4rem;
  }

  @media (min-width: 600px)  {
    width: 100%;
  }
  @media (min-width: 1024px)  {
    padding: 0 0 50px 0;
    width: 90%;
    

    .te-header {
      font-family: "Rubik", sans-serif;
      font-size: 4rem;
      margin: 0;
    }
  }
`



const Teaching = () => {
    const [content, setContent] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        fetch('https://api.storyblok.com/v2/cdn/stories/teaching?version=published&token=sTO5zBZz3QBovHIeO7jTfwtt&cv=1726749708')
            .then(response => response.json())
            .then(data => {
                setContent(data?.story?.content?.teaching)
            })
            .catch(error => {
                // Handle any errors
            });
    }, [])


    return (
        <>
            <Teach>
                <div className="te-header">
                    <h1>Teachings</h1>
                </div>
                <ul className="te-list">
                    {content?.map((item, idx) => {
                        return  (
                            <li>
                                {item.title && <h2>{item.title}</h2>}
                                {item.subline && <p className="te-subline">{item.subline}</p>}
                                {(item.periodTitle || item.fromYear || item.tillYear) &&
                                    <div className="te-subline te-row">
                                    <p>{item.periodTitle}</p>
                                    {(item.fromYear && item.tillYear) && <p>{item.fromYear}-{item.tillYear}</p>}
                                </div>
                                }
                                <div className="te-gap">
                                    {item.institutionName && <p className="te-location ">{item.institutionName}</p>}
                                    {item.organisation && <p className="te-location">{item.organisation}</p>}
                                    {item.project && <p className="te-location">{item.project}</p>}
                                </div>
                                {item?.description && <div className="te-text">{render(item?.description)}</div>}
                                {item.link?.url && <a className="te-link" href={item.link.url} target="_blank">LINK</a>}

                            </li>)
                    })}
                </ul>
            </Teach>
        </>
    );
};

export default Teaching;
