import React, { useEffect, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";



const Ex = styled.div`
  width: 100%;
  padding: 2vw 0;

  .ex-header {
    font-family: "Rubik", sans-serif;
    font-size: 1.3rem;
    margin: 0;
  }

  .ex-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .ex-link {
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;

    &--disabled {
      pointer-events: none;
      box-shadow: inset 0 0 0 1px black;
    }
  }

  .ex-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    filter: blur(0px);
    transition: all .2s ease;
    object-fit: cover;
  }

  h3 {
    position: absolute;
    transform: translate(-50%, 50%);
    left: 50%;
    bottom: 50%;
    color: black;
    font-family: "Rubik", sans-serif;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    margin: 0;
    transition: all .2s ease;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
  }

  @media (min-width: 600px)  {
    width: 100%;

    .ex-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    .ex-link {
      height: calc(98vw / 2);
    }



  }
  @media (min-width: 1024px)  {
    padding-bottom: 50px;
    width: 90%;

    .ex-header {
      font-family: "Rubik", sans-serif;
      font-size: 4rem;
      margin: 0;
    }

    .ex-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap:  2rem;
    }

    h3 {
      position: absolute;
      transform: translate(-50%, 50%);
      left: 50%;
      bottom: 50%;
      color: transparent;
      font-family: "Rubik", sans-serif;
      font-size: 2rem;
      width: 100%;
      text-align: center;
      margin: 0;
      transition: all .2s ease;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #000;
    }

    

    .ex-link {
      height: 200px;

      &:hover {
        .ex-img {
          filter: blur(2px) ;
          transform: scale(10.5);
        }
        
        h3 {
          color: white
        }
      }
    }
  }
`



const Exhibition = () => {
    const [content, setContent] = useState([])
    const [load, setLoad] = useState(false)

    useEffect(() => {
        fetch('https://api.storyblok.com/v2/cdn/stories/exhibitions?version=published&token=sTO5zBZz3QBovHIeO7jTfwtt&cv=1726749708')
            .then(response => response.json())
            .then(data => {
                setContent(data?.story?.content?.exhibition)
            })
            .catch(error => {
                // Handle any errors
            });
    }, [])


    return (
        <>
            <Ex>
                <div className="ex-header">
                    <h1>Exhibitions</h1>
                </div>
                <div className="ex-grid">
                    {content?.map((item, idx) => {
                        return  (
                            <Link onClick={()=> setLoad(item._uid)} to={`exhibition/${item.title.replace(' ', '_').toLowerCase()}`} key={idx} className={`ex-link ${load === item._uid && 'ex-link--disabled'}` }>
                                <img alt={item?.photos?.[0].alt} className="ex-img" src={item?.photos?.[0].filename} />
                                <h3>{item.title}</h3>
                            </Link>)
                    })}
                </div>
            </Ex>
        </>
    );
};

export default Exhibition;
