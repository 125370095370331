import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import MainLayer from "./Structure/MainLayer";
import BgLightsBig from "./Header/BgLightBig";
import Container from "../Context/Container";
import Cursor from "../Assets/Cursor/Asset 2.png";

import {
  useTransitionContextState,
  TransitionContext,
} from "./NavLogic/TransitionContext";
import ScreenSaver from "./Main/ScreenSaver";

const MainCont = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: url(${Cursor}), auto;
`;

const App = () => {
  const transitionContext = useTransitionContextState();
  const myRef = useRef(null);

  const initialTime = 18;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    const currRef = myRef.current

    currRef.addEventListener('mousemove', handleMouseMove);
    return () => {
      currRef.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleMouseMove = (event) => {
    setTimeRemaining(180)
  };
  var w = window.innerWidth;


  return (
    <Container>
      <TransitionContext.Provider value={transitionContext}>
        <Router>
          <MainCont ref={myRef}>
            {timeRemaining === 0 && w > 1040 && <ScreenSaver/>}
            <BgLightsBig />
            <MainLayer />
          </MainCont>
        </Router>
      </TransitionContext.Provider>
    </Container>
  );
};

export default App;
