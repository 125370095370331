import React from 'react';
import { Route } from 'react-router-dom';
import TransitionManager from './TransitionManager';

const TransitionRoute = (props) => {
  return (
    <Route path={props.path} exact>
      {({ match }) => {
        return (
          <TransitionManager
            key={props.path}
            path={props.path}
            component={props.component}
            match={match}
          />
        );
      }}
    </Route>
  );
};
export default TransitionRoute;
