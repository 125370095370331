import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { useLocation} from 'react-router-dom';
import {Context} from "../../Context/Context";
import { render } from 'storyblok-rich-text-react-renderer';
import YouTube from 'react-youtube';
import ReactPlayer from "react-player"


const Cont = styled.div`
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
    padding-bottom: 10px;
    
    h1 {
      text-transform: uppercase;
      font-size: 2.5rem;
      margin: 2rem 0 0 0;
      text-shadow: 0px 0px 2px black;
      text-align: left;
      font-family: "Rubik", sans-serif;
    }
  
    h2, h3 {
      text-align: right;
      font-family: "Rubik", sans-serif;
      margin-top: 0;
      margin-bottom: 2rem;
    }
  
  
    .l-heading-shows {
      margin-top: 3rem;
     }
  
    .l-text {
      font-family: "Rubik", sans-serif;
      padding: 2vw;
      font-size: 1rem;
    }
  
    .l-youtube {
      width: 100%;
      height: 460px;
    }

    .l-images {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 3rem;
    }

  .l-image {
    width: 100%;
  }

  .l-shows {
    display: flex;
    flex-direction: column;
  }
  
  .l-show {
    display: flex;
    flex-direction: row;
    font-family: "Rubik", sans-serif;
    gap: 1rem;
    
    &::before {
      content: "•";
    }

    p {
      margin: 0;
    }
    
    a {
      margin: 0;
      padding: 0;
      color: #ff00e5;
      text-decoration: none;

      &:hover {
        color: blue;
      }
    }
  }
  
  .l-soundcloud {
    margin: 2rem 0 1rem 0;
    width: 100%;
  }
  
  @media (min-width: 600px)   {} 
  @media (min-width: 1024px)  {
    list-style: none;
    width: calc(100% - 10vw);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
    margin-bottom: 2vw;

    h1 {
      font-size: 3rem;
    }

    .l-text {
      font-family: "Rubik", sans-serif;
      font-size: 1rem;
      padding: 0;
    }
    
    .l-image {
      width: calc(50% - 10px);
    }

    @media (min-width: 1240px) {
      width: calc(100% - 10vw);

      h1 {
        font-size: 5rem;
      }
      
      .l-youtube {
        width: 100%;
        ratio: 16 / 9;
      }}
  }
`;

const ExhibitionDetails = (props) => {

  const {exhibitions, } = useContext(Context);
  const [current, setCurrent] = useState(undefined)
  const location = useLocation();
  const findCurrent = useCallback(()=> {
      const pathArr = location.pathname.split('/')
      const res =  exhibitions.find(i => i.title.toLowerCase().replace(' ', '_') === pathArr[pathArr.length - 1])
      setCurrent(res)
  }, [exhibitions, location.pathname])

  useEffect(()=> {
    findCurrent()
  }, [findCurrent])

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            controls: 1
        },
    };

    function ready(event) {
        event.target.pauseVideo();
    }

    const onPlay = () =>  {
        console.log('playing');
    }

  if(!current) return null

  return (
      <Cont>
        <h1>{current?.title}</h1>
        <h2>Since {current?.since}</h2>
          {current?.YoutubeVideoId && <YouTube
              videoId={current?.YoutubeVideoId} opts={opts} onReady={ready} className="l-youtube"    // defaults -> noop
          />}
        <div className="l-images">

        {current?.photos?.length !== 0 && current.photos?.map((p, i) => {
          return <img className="l-image" src={p.filename} alt={p.alt} />
        })}
        </div>
          {current?.SouncloudLink && <div className="l-soundcloud">
              <ReactPlayer
                  url={current?.SouncloudLink}
                  width="100%"
                  height="150px"
              />
          </div>}

        <div className="l-text">{render(current?.description)}</div>

          <h3 className="l-heading-shows">Shown at</h3>
          <ul className="l-shows">
          {current?.alreadyShowAt && current.alreadyShowAt.map(i => <li className="l-show">
              {i.year && <p>{i.year}</p>}
              {i.location && <p>{i.location}</p>}
              {i.event &&  <p>{i.event}</p>}
              {i.country &&  <p>{i.country}</p> }
              {i.url?.url && <a href={i.url.url} without rel="noreferrer" target="_blank">Visit</a> }
          </li>)}
          </ul>
      </Cont>
  );
};

export default ExhibitionDetails;
