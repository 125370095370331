import React, {useEffect, useContext, useMemo} from "react";
import { Context } from "../../Context/Context";
import styled from "styled-components";
import LinkOrStatic from "../NavLogic/LinkOrStatic";
import { TimelineMax } from "gsap/all";
var w = window.innerWidth;

const Menu = ["Teaching", "Music", "Exhibition", "About", "Home"];

const NavList = () => {
  const data = useContext(Context);
  const tl = new TimelineMax()

  useEffect(() => {
    if (w > 768) {
      if (data.navButtonHover) {
        tl.to(["#menuCont"], 0.5, { bottom: "0%" }).staggerTo(
          ["#menuCont .link"],
          0.2,
          {
            opacity: "1",
          },
          0.1
        );
      } else {
        tl.staggerTo(
          ["#menuCont .link"],
          0.2,
          {
            opacity: "0",
          },
          0.1
        ).to(["#menuCont"], 0.5, { bottom: "-100%" });
      }
    } else {
      if (data.navButtonHover) {
        tl.to(["#menuCont"], 0.5, { top: "20.2vh" }).staggerTo(
          ["#menuCont .link"],
          0.2,
          {
            opacity: "1",
          },
          0.1
        );
      } else {
        tl.staggerTo(
          ["#menuCont .link"],
          0.2,
          {
            opacity: "0",
          },
          0.1
        ).to(["#menuCont"], 0.5, { top: "100vh" });
      }
    }
  }, [data.navButtonHover, tl]);

  const handleMobileNavSelect = () => {
    if (w < 769) {
      data.setNavButtonHover((prev) => !prev);
    }
  };
console.log(Menu)
  return (
    <MainContainer id="menuCont">
      <ul>
        {Menu.map((item, i) => {
          return (

            <LinkOrStatic
              key={i}
              className="link"
              to={`/`+ (item.toLowerCase() === 'home' ? '' : item.toLowerCase())}
              text={item}
              name={item}
              onClick={handleMobileNavSelect}
            ></LinkOrStatic>
          );
        })}
      </ul>
    </MainContainer>
  );
};

const MainContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin: 0;
    padding: 0;
    background: white;

    ul {
      width: 100%;
      height: 100%;
      background: transparent;
      margin: 0;
      padding: 0 0 0 0%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      /* transform: rotate(180deg); */
    }

    .link {
      writing-mode: vertical-rl;
      display: flex;
      flex: 1;
      height: 100%;
      padding-top: 0%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      opacity: 0;
    }
  
  @media (min-width: 600px)   {
  } 
  
  @media (min-width: 1024px)  {
    width: 10vw;
    height: 100%;
    position: absolute;
    left: 35%;
    bottom: -100%;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-right: 1px solid black;
    border-left: 1px solid black;

    margin: 0;
    padding: 0;
    background: white;
    z-index: 1;

    ul {
      width: 100%;
      height: 100%;
      background: transparent;
      margin: 0;
      padding: 0 0 0 0%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      transform: rotate(180deg);
    }

    .link {
      writing-mode: vertical-rl;
      display: flex;
      flex: 1;
      height: 100%;
      padding-top: 0%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      opacity: 0;
    }
  }
`;

export default NavList;
