import React, { Fragment, useState } from "react";
import { Context } from "./Context";

//We pass in props to the Container so we can access the children
const Container = (props) => {
  //Create state and setState functions with useState as needed
  const [navButtonHover, setNavButtonHover] = useState(false);
  const [screenSaver, setScreenSaver] = useState('')
  const [exhibitions, setExhibitions] = useState([])
  const [music, setMusic] = useState([])


  return (
    //Pass the states and setState functions through the context.provider. Wrap around the children.
    <Context.Provider
      value={{
        navButtonHover,
        setNavButtonHover,
        setScreenSaver, screenSaver,
        exhibitions, setExhibitions,
        music, setMusic
      }}
    >
      <Fragment>{props.children}</Fragment>
    </Context.Provider>
  );
};

export default Container;
