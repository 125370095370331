import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Nav from "./Nav";
import ContactModul from "./ContatcModul";
import NavList from "./NavList";

const Header = () => {
  return (
    <MainContainer>
      <div className="infoLine">

          <Logo />


        <Nav />
        <ContactModul />
      </div>

      <NavList />
    </MainContainer>
  );
};

const MainContainer = styled.div`
    width: 100%;
    height: 12vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    position: relative;
    padding-left: 0%;

    a {
      text-decoration: none;
    }

    .infoLine {
      width: 100%;
      height: auto;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
    }
  

  @media (min-width: 600px)   {
    height: 20vh;
    .infoLine {
      height: 100%;
    }
  } 
  @media (min-width: 1024px)  {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    padding-left: 0%;
    /* background: white; */
    z-index: 2;

    a {
      text-decoration: none;
    }
    .infoLine {
      width: 35%;
      height: 100%;
      border-right: 1px solid black;
      display: flex;
      background: white;

      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }
  }
`;

export default Header;
