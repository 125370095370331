import React, {useContext, useState} from "react";
import styled from "styled-components";
import { Context } from "../../Context/Context";
import {Link} from "react-router-dom";

const Ex = styled.div`
  width: 100%;
  padding: 2vw 0;


  .mu-header {
    font-family: "Rubik", sans-serif;
    font-size: 1.3rem;
    margin: 0;
  }

  .mu-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: .5rem;
  }

  .mu-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    box-shadow: 0 0 0 black;
    padding: 1rem;
    border-radius: .5rem;
    transition: all .2s ease-in-out;

    &--disabled {
      pointer-events: none;
    }
  }

  .mu-img {
    width: 100%;
    height: 100%;
    top: 0;
    filter: blur(0px);
    transition: all .2s ease;
    object-fit: cover;
    margin-bottom: .5rem;
  }

  h3 {
    color: black;
    font-family: "Rubik", sans-serif;
    font-size: 1.3rem;
    width: 100%;
    margin: 0;
  }
  
  .mu-title {
    font-weight: 300;
    font-style: italic;
  }

  .mu-year {
    font-size: 1rem;
    font-weight: 100;
    font-style: italic;
    font-size: .8rem;
  }

  .mu-buy-link {
    text-align: right;
    color: white;
    text-decoration: none;
    margin-top: 1rem;
    font-family: "Rubik", sans-serif;
    background-image: linear-gradient(45deg, red, magenta);
    width: fit-content;
    align-self: flex-end;
    padding: .4rem .8rem;
    border-radius: .25rem;
    font-weight: bold;
    border: 2px solid white;

    &:hover {
      font-style: italic;
    }
    
    &:active {
      background: white;
      color: red;
    }
  }
  
  .mu-row {
    display: flex;
    justify-content: flex-end;
    gap: .5rem;
  }

  @media (min-width: 600px) {
    width: 100%;

    .mu-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      padding: .5rem;
    }

  }
  @media (min-width: 1024px) {
    padding-bottom: 50px;
    width: 90%;



    .mu-header {
      font-family: "Rubik", sans-serif;
      font-size: 4rem;
      margin: 0;
    }

    .mu-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      padding: 0;
    }

    .mu-link {
      overflow: hidden;
      position: relative;

      &:before {
        content: "";
        width: 900%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: linear-gradient(112deg, white, #ff8ac6);
        transition: all 0.3s linear;
        z-index: -1;
      }

      &:hover {
        &:before {
          left: -800%;
        }
        
        .mu-buy-link {
          border: 2px solid white;
        }
      }
    }
  }
`

const Music = () => {

  const [load, setLoad] = useState(false)
  const { music} = useContext(Context);

  return (
    <>
      <Ex>
        <div className="mu-header">
          <h1>Music</h1>
        </div>
        <div className="mu-grid">
          {music?.map((item, idx) => {
            return  (<div className="mu-link">
                  <img className="mu-img" alt="Cover" src={item?.Images?.[0].filename} />
                  <h3>{item.Artist}</h3>
                  <h3 className="mu-title">{item.AlbumTitle}</h3>
                  <h3 className="mu-year">{item.ReleaseYear}</h3>
                  <div className="mu-row">
                    <Link onClick={()=> setLoad(item._uid)} to={`music/${item.AlbumTitle.replace(' ', '_').toLowerCase()}`} key={idx} className={`mu-buy-link ${load === item._uid && 'mu-link--disabled'}` }>Details</Link>
                    <a rel="noreferrer" className="mu-buy-link" target="_blank" href={item?.BuyAt?.url}>Buy</a>
                  </div>

                </div>
            )
          })}

        </div>
      </Ex>
    </>
  );
};

export default Music;
