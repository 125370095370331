import React from "react";
import styled from "styled-components";
import { Route, Link } from "react-router-dom";

const RouteB = styled(Route)`
  color: yellow;
`;

const LinkName = styled.p`
  margin: 10px 0 0 0;

  background: -webkit-linear-gradient(45deg, #ff00e5, #ff1e00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  writing-mode: vertical-rl;
`;

const LinkCont = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
 
  &:active .hoverLayer {
    background-image: linear-gradient(white, white);
  }

  &:hover .linkOrigin {
    background: -webkit-linear-gradient(45deg, #fff, #ff1e00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 100%;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "Rubik Mono One", sans-serif;
  font-weight: 300;
  font-size: 11.5vw;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  &:hover {
    animation: navHover .2s linear infinite forwards;

  }

  .hoverLayer {
    width: 0;
    height: 0;
    background-image: linear-gradient(#880dff, red);
    border-radius: 50vw;
    position: absolute;
    z-index: -1;
    top: 50%;
  }

  @media (min-width: 600px)  {
  }
  @media (min-width: 1024px) {
    text-decoration: none;
    font-family: "Rubik Mono One", sans-serif;
    font-weight: 300;
    font-size: 1.5vw;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-image: linear-gradient(black, rgba(196, 196, 196, 0.59), rgb(255, 255, 255));


    @keyframes navHover {
      0% {
        background-image: linear-gradient(black 75%, white);
      }

      20% {
        background-image: linear-gradient(black, magenta);
      }

      40% {
        background-image: linear-gradient(black, red);

      }

      60% {
        background-image: linear-gradient(magenta, black);
      }

      80% {
        background-image: linear-gradient(black, white);

      }
    }


  }
`;

const LinkOrStatic = (props) => {
  const path = props.to;
  return (
    <>
      <RouteB exact path={path}>
        {({ match }) => {
          if (match) {
            return (
              <LinkCont className={props.className}>
                <NavLink to={props.to} onClick={props.onClick} id={props.id}>
                  <LinkName className="text">{props.text}</LinkName>
                  {props.children}
                </NavLink>
              </LinkCont>
            );
          }
          return (
            <LinkCont className={props.className}>
              <NavLink
                name={props.name}
                to={props.to}
                onClick={props.onClick}
                id={props.id}
              >
                <div className="hoverLayer"></div>
                <LinkName className="linkOrigin text">{props.text}</LinkName>
              </NavLink>
            </LinkCont>
          );
        }}
      </RouteB>
    </>
  );
};
export default LinkOrStatic;
