import styled from "styled-components";
import * as dayjs from 'dayjs'

const Li = styled.li`
    width: 100%;
    border-bottom: 1px solid black;
    padding: 0vw 0 4vw 4vw;

  .a-date {
      display: flex;
      flex-direction: row;
      width: 100%;
      
      p {
        align-items: flex-start;
        font-size: 1rem;
       
        width: fit-content;
      }
      
      .a-till {
        margin-left: .25rem;
      }
    }

    h2 {
      text-transform: uppercase;
      font-size: 2rem;
      text-shadow: 0px 0px 2px black;
      padding: 0 4vw 0 0;
      margin: 0;
    }

    h4 {
      font-size: 4vw;
      margin: 0vw 0 0vw 2vw;
      text-shadow: 0px 0px 2px black;
      font-weight: 300;
    }

    p {
      font-family: "Rubik", sans-serif;
      font-size: 0.8vw;
      text-align: justify;
      width: 80%;
      margin: 2vw 0;
      text-shadow: 0px 0px 2px black;
    }
 
  @media (min-width: 600px)   {}
  @media (min-width: 1024px)   {
   
    width: 80%;
    margin: 0 0 1.5vw 0vw;
    text-shadow: 0px 0px 2px black;
    padding-bottom: 1.5vw;
    
    & :nth-child(1n) {
      margin: 0
    }

    .a-date {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 20px;
    }
    
    .a-h2 {
      margin-top: 20px ;
    }
    
    .a-h3 {
      font-weight: 100;
    }

    .a-location {
      margin-top: 20px;
      margin-bottom: 2rem;
    }
    
    .a-link {
      
      color: #ff00e5;
      text-decoration: none;
      
      &:hover {
        color: blue;
      }
    }
  }

`
const Announcement = (props)=> {
    const {title, subLine, location, from, till, link} = props.data


    return <Li>
        <div className="a-date">
            <p>{dayjs(from).format('DD.MM.YYYY')}</p>
            {till && <p className="a-till"> - {dayjs(till).format('DD.MM.YYYY')}</p>}
        </div>

        <div className="a-title">
            <h2 className="a-h2">{title}</h2>
            <h3 className="a-h3">{subLine}</h3>
        </div>

        <h3 className="a-h3 a-location"><i>at</i> {location}</h3>
        {link.url && <a className="a-link" href={link.url} target="_blank" rel="noreferrer">Visit</a>}
    </Li>
}

export default Announcement
