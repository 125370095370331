import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Context } from "../../Context/Context";
import { gsap } from "gsap/all";

const NavButton = () => {
  const data = useContext(Context);

  const HandleClick = () => {
    data.setNavButtonHover(!data.navButtonHover);
  };

  useEffect(() => {
    if (data.navButtonHover) {
        gsap.to([".layerTwo"], 0.3, { right: 0 });
    } else {
        gsap.to([".layerTwo"], 0.3, { right: 250 });
    }
  }, [data.navButtonHover]);
  return (
    <MainContainer onClick={HandleClick}>
      <button className="layer"></button>
      <button className="layerTwo">
        <i className="fas fa-times"></i>
      </button>

      <p>M</p>
    </MainContainer>
  );
};

const MainContainer = styled.div`
    width: 100%;
    height: 12vh;
    border-left: 1px solid black;
    cursor: pointer;
    padding: 0px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .layer {
      position: absolute;
      width: 100%;
      height: 12vh;
      left: -100%;
      background: white;
      transition: all 0.3s linear;
      z-index: 9;
      outline: none;
      border: none;
      cursor: pointer;
    }
    .layerTwo {
      position: absolute;
      width: 100%;
      height: 12vh;
      right: -100%;
      background: pink;
      transition: all 0.3s linear;
      z-index: 12;
      outline: none;
      border: none;
      cursor: pointer;

      &:active i {
        font-size: 1.5vw;
      }

      i {
        font-size: 12vw;
        transition: all 0.4s linear;
      }
    }

    p {
      font-family: "Rubik Mono One", sans-serif;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-size: 12vw;
      /* color: white; */
      -webkit-text-stroke: 1px black;
      position: relative;
      z-index: 10;
      transition: all 0.3s linear;
      background: -webkit-linear-gradient(45deg, #fff, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: white;
      text-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;

      @keyframes close {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  

  @media (min-width: 600px)   {
    height: 20vh;
    
    .layerTwo {
      height: 20vh;
    }
  } 
  @media (min-width: 1024px)  {
    width: 100%;
    height: 2vw;
    justify-content: center;
    align-items: center;
    padding: 0 0 5px 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: none;
    
    &:hover p {
      background: -webkit-linear-gradient(45deg, #ff00e5, #ff1e00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:active p {
      font-size: 1.5vw;
    }

    .layer {
      position: absolute;
      width: 100%;
      height: 2vw;
      left: -100%;
      background: white;
      transition: all 0.3s linear;
      z-index: 9;
      outline: none;
      border: none;
      cursor: pointer;
    }
    .layerTwo {
      position: absolute;
    
      width: 100%;
      height: 2.5vw;
      right: 100%;
      background: lightgreen;
      transition: all 0.3s linear;
      z-index: 12;
      outline: none;
      border: none;
      cursor: pointer;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAJElEQVQYV2M8w/D/v/F/Bjhg/P//PxKXgQEucJaRgQGkEkMFAKX8EfVd9sWTAAAAAElFTkSuQmCC)
        repeat;

      &:hover i {
        animation: close 1s ease-in-out infinite;
      }

      &:active i {
        font-size: 1.5vw;
      }

      i {
        font-size: 2vw;
        transition: all 0.4s linear;
        position: relative;
        top: 3px;
      }
    }

    p {
      font-family: "Rubik Mono One", sans-serif;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      font-size: 2vw;
      -webkit-text-stroke: 1px black;
      position: relative;
      top: 4px;
      z-index: 10;
      transition: all 0.3s linear;
      background: -webkit-linear-gradient(45deg, #fff, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: white;
      text-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;

      @keyframes close {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
`;

export default NavButton;
