import { TimelineMax } from "gsap";
const startStyle = { left: "-100%", autoAlpha: 0.5 };

export const slowFadeInAndDropFromAboveThenLeftRight = (
  trackTimelineAndDone
) => (node, done) => {
  const timeline = new TimelineMax();
  if (trackTimelineAndDone) {
    trackTimelineAndDone({ timeline, done });
  }
  timeline.set(node, startStyle);
  timeline
    .to(
      node,
      1,

      {
        left: "0%",
      }
    )
    .to(node, 0.5, { autoAlpha: 1 })
    .to(node, 0.5, {
      onComplete: done,
    });
};
export const slowFadeOutAndDrop = (trackTimelineAndDone) => (node, done) => {
  const timeline = new TimelineMax();
  if (trackTimelineAndDone) {
    trackTimelineAndDone({ timeline, done });
  }
  timeline.to(node, 1, {
    left: "200%",
    autoAlpha: 0.5,
    onComplete: done,
  });
};
