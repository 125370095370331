import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { useLocation} from 'react-router-dom';
import {Context} from "../../Context/Context";
import { render } from 'storyblok-rich-text-react-renderer';


const Cont = styled.div`
  list-style: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  z-index: 11;
  padding-bottom: 10px;

  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 2rem 0 0 0;
    text-shadow: 0 0 2px black;
    text-align: left;
    font-family: "Rubik", sans-serif;
  }

  h2, h3 {
    text-align: right;
    font-family: "Rubik", sans-serif;
    margin-top: 0;
    margin-bottom: 2rem;
  }
  
  .re-heading-shows {
    margin-top: 3rem;
  }

  .re-text {
    font-family: "Rubik", sans-serif;
    padding: 2vw;
    font-size: 1rem;
  }

  .re-youtube {
    width: 100%;
    height: 460px;
  }

  .re-images {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 3rem;
    padding: 0 2vw;

  }

  .re-image {
    width: 100%;
  }

  .re-shows {
    display: flex;
    flex-direction: column;
  }

  a {
    margin: 0;
    color: white;
    font-weight: 900;
    text-decoration: none;
    font-family: "Rubik", sans-serif;
    background-image: linear-gradient(45deg, rgba(252, 0, 252, 0.96), rgba(255, 0, 0, 0.94));
    padding: .5rem 2rem;
    border-radius: 1rem;
    transition: all 1s ease-in-out;
    box-shadow: 0 0 0 0 cyan;

    &:hover {
      color: white !important;
      animation: hoverLink .3s ease-in-out infinite forwards;
    }

    @keyframes hoverLink {
      0% {
        background-image: linear-gradient(45deg, rgba(252, 0, 252, 0.96), rgba(255, 0, 0, 0.94));
      }
      20% {
        background-image: linear-gradient(45deg, rgba(252, 0, 113, 0.96), rgba(255, 0, 0, 0.94));
      }
      40% {
        box-shadow: 10px 0 10px 0 red;
        background-image: linear-gradient(45deg, rgba(121, 66, 189, 0.96), rgba(30, 8, 232, 0.94));
      }
      60% {
        background-image: linear-gradient(45deg, rgba(90, 2, 243, 0.96), rgba(255, 0, 0, 0.94));
      }
      80% {
        background-image: linear-gradient(45deg, rgba(224, 123, 161, 0.96), rgba(255, 0, 0, 0.94));
      }
    }
  }

  .re-show {
    display: flex;
    flex-direction: row;
    font-family: "Rubik", sans-serif;
    gap: 1rem;

    &::before {
      content: "•";
    }

    p {
      margin: 0;
      padding: 0;
    }


  }

  @media (min-width: 600px)  {
  }
  @media (min-width: 1024px) {
    list-style: none;
    width: calc(100% - 10vw);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
    margin-bottom: 2vw;
    padding-bottom: 2vw;

    h1 {
      font-size: 3rem;
    }

    .re-text {
      font-family: "Rubik", sans-serif;
      font-size: 1rem;
      padding: 0
    }

    .re-images {
      padding: 0 ;
    }


    .re-image {
      width: calc(50% - 10px);
    }


    @media (min-width: 1240px) {
      width: calc(100% - 10vw);

      h1 {
        font-size: 5rem;
      }

      .re-youtube {
        width: 100%;
        aspect-ratio: 16 / 9;
      }
    }
  }
`;

const ReleaseDetails = (props) => {

    const {music } = useContext(Context);
    const [current, setCurrent] = useState(undefined)
    const location = useLocation();

    const findCurrent = useCallback(()=> {
        const pathArr = location.pathname.split('/')
        const res =  music.find(i => i.AlbumTitle.toLowerCase().replace(' ', '_') === pathArr[pathArr.length - 1])
        setCurrent(res)
    }, [music, location.pathname])

    useEffect(()=> {
        findCurrent()
    }, [findCurrent])


    if(!current) return null

    return (
        <Cont>
            <h1>{current?.Artist}</h1>
            <h2>{current?.AlbumTitle}</h2>
            <a rel="noreferrer" className="re-link" target="_blank" href={current?.BuyAt?.url}>Buy</a>
            <div className="re-text">{render(current?.Description)}</div>
            <div className="re-images">

                {current?.Images?.length !== 0 && current.Images?.map((p, i) => {
                    return <img className="re-image" src={p.filename} alt={p.alt} />
                })}
            </div>
        </Cont>
    );
};

export default ReleaseDetails;
