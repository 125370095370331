import React, { useEffect, useState} from "react";
import styled from "styled-components";
import SectionBlueprint from "./SectionBlueprint";
import { render } from 'storyblok-rich-text-react-renderer';




const Div = styled.div`
  padding: 0 2vw !important;
  
  p {
    margin-top: 0;
    padding: 2vw !important;
    font-size: 1rem;
    }

  @media (min-width: 600px)   {
    
  } 
  @media (min-width: 1024px)  {

    p {
      font-size: 1rem;
      padding-top: 0 !important;
      margin: 0;
    }
  }


`;

const About = () => {
    const [content, setContent] = useState({about: undefined, photo: undefined})

    useEffect(() => {
        fetch('https://api.storyblok.com/v2/cdn/stories/about?version=published&token=sTO5zBZz3QBovHIeO7jTfwtt&cv=1726749708')
            .then(response => response.json())
            .then(data => {
                setContent({about: data?.story?.content?.about, photo: data?.story?.content?.photo})
            })
            .catch(error => {
                // Handle any errors
            });
    }, [])


    return (
    <>
      <SectionBlueprint img={content?.photo?.filename} title="ABOUT">
        <Div>
            {render(content?.about)}
        </Div>
      </SectionBlueprint>
    </>
  );
};

export default About;
