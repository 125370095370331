import React, { useEffect, useState} from "react";
import styled from "styled-components";
import SectionBlueprint from "./SectionBlueprint";
import ImageOne from "../../Assets/Photos/Sound/banu.gif";
import Anouncement from "./Anouncement";

const Div = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 90%;
    border-bottom: 0px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
  
    &:hover h2 {
      animation: blinkHoverWork 0.5s linear infinite;
    }
    &:nth-child(1) {
      border-top: 0px solid black;
    }

    .column {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
    }

    .imageCont {
      width: 100%;

      &::before {
        content: "";
        border-radius: 50%;
        filter: blur(20px) opacity(0.4);
        width: 40vw;
        height: 40vw;
        background-image: linear-gradient(45deg, #ff00e5, #ff1e00);
        position: absolute;
        z-index: -1;
        transform: translate(50%);
        left: -50%;
        top: 0%;
      }
    }
  @media (min-width: 600px)   {} 
  @media (min-width: 1024px)  {
    list-style: none;
    margin: 0;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 11;
  }
`;

const Home = () => {
    const [content, setContent] = useState([])


    useEffect(() => {
        fetch('https://api.storyblok.com/v2/cdn/stories/home?version=published&token=sTO5zBZz3QBovHIeO7jTfwtt&cv=1726749708')
            .then(response => response.json())
            .then(data => {
                setContent(data?.story?.content?.announcementsAndDates)
            })
            .catch(error => {
                // Handle any errors
            });
    }, [])


  return (
    <>
      <SectionBlueprint img={ImageOne} title="Current">
        <Div>
            {content?.length !== 0 && content?.map((item, i) => {
              return <Anouncement data={item} key={i} />
            })}
        </Div>
      </SectionBlueprint>
    </>
  );
};

export default Home;
